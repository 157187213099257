.tile-grid {
    display: grid;
    position: absolute;
    top: 12rem;
    left: 15%;
    bottom: 0;
    right: 15%;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-row-gap: 1rem;
    grid-template-rows: repeat(auto-fit, 200px);
}