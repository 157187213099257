@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html, body {
  font-size: 10px;
}

body {
  font-family: 'Open Sans';
}

.App {
padding: 1.5rem;
}

/*
#160D07 Smoky Black
#503726 Cafe Noir
#725F4C Raw Umber
#240A01 Dark Sienna
#6C3519 Seal Brown
#FAA405 Orange Web
#A58B73 Beaver


*/